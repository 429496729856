---
title: 'External dependencies'
metaTitle: 'Admin-UI Docs | Getting started > External dependencies'
metaDescription: 'The Admin-UI project uses other libraries, internal & external to Coveo'
---

## Plasma

[Plasma](https://plasma.coveo.com) is Coveo’s platform design system.

It currently includes several packages, but only 2 will interest us:

-   `@coveord/plasma-mantine`
-   `@coveord/plasma-react-icons`

This library is maintained by the [Frontend Foundation](https://coveord.atlassian.net/wiki/spaces/PENG/pages/4197777463/Frontend+foundation+-+Team) team.

### What is @coveord/plasma-mantine or Plasmantine

This package is essentially a package for installing all [Mantine](https://mantine.dev) dependencies,
configuring Mantine as desired and adding components that are not available in Mantine but are essential to our design system.

> Note: This dependency is only installed in the @components/mantine package, so it's from this package that you'll have access to everything available from Mantine.

### What is @coveord/plasma-react-icons

This package contains the entire Coveo iconography library.
Don't hesitate to talk to your UX team colleague to find out which icons and format you should use for your development.

> Note: This dependency can be installed in any package where you need it.

**How to use it**

Simply copy and paste what you find in [our iconography on Plasma website](https://plasma.coveo.com/foundations/Iconography).

```javascript
import {DeleteSize16Px} from '@coveord/plasma-react-icons';

// use the 32px format
<DeleteSize32Px height={32} />

// use the 24px format
<DeleteSize24Px height={24} />

// use the 16px format
<DeleteSize16Px height={16} />
```

### Plasma update

A command in the project root will allow you to update our **Plasma** library to its latest version in each `package.json` file and the `pnpm-lock.yaml` file.

```bash
pnpm update:plasma
```

## Platform Client

The main goal of this package is to provide an easy to configure and straightforward way of querying Coveo Cloud APIs using JavaScript.

This library is maintained by the [Frontend Foundation](https://coveord.atlassian.net/wiki/spaces/PENG/pages/4197777463/Frontend+foundation+-+Team) & the [Developer eXperience](https://github.com/orgs/coveo/teams/dx) teams.

### How to use Platform Client

We have configured **Platform Client** in 2 different instances:

-   **Platform** is the default PlatformClient instance of the Admin-UI project. This instance is configured with several basic response handlers.
-   **PlatformNoHandlers** another instance of PlatformClient from the Admin-UI project, but not configured with our basic responses handlers.

> Note: This dependency is only installed in the @core/api package, so it's from this package that you'll have access to everything available from Platform Client.

### Platform Client update

A command in the project root will allow you to update our **Platform Client** library to its latest version in each `package.json` file and the `pnpm-lock.yaml` file.

```bash
pnpm update:platform-client
```

## To update specific dependencies

To update a dependency to a specific version in the various project `package.json` files and in the `pnpm-lock.yaml` file.

```bash
pnpm update --recursive packageName@version
```

You can also automatically select the latest package version.

```bash
pnpm update --recursive packageName --latest
```

> Note: Any update of the dependencies or version changes done in package.json files require the [`pnpm-lock.yaml` file](https://pnpm.io/git#lockfiles) to be updated (regenerated) and committed.
> This file is at the root of the repo and is unique.

## Running Admin-UI with other packages that we maintain

**Why do you want to do this?**

We can run other packages with Admin-UI to help debug or test changes locally.

If you did some changes in the [`platform-client`](https://github.com/coveo/platform-client) package or the [`plasma`](https://github.com/coveo/plasma) package, you can test them directly in the Admin-UI before having to publish these changes on npm.

Make sure you have your Admin-UI setup locally following this [guide](/getting-started/01-configuring-your-machine).

### Local changes from plasma

1. Make sure your Admin-UI local server is not running.
2. Install all your dependencies with `pnpm install`. If you do this after the link, the link will be broken and you will have to redo it.
3. Link the _plasma_ package to the _admin-ui_ with `pnpm link:plasma`. We assume you have a folder named `plasma` as a sibling of `Admin-UI` folder.
4. Build the _plasma_ package according to its [documentation](https://github.com/coveo/plasma#setup).
5. [Launch the Admin-UI local setup](/getting-started/02-install-and-launch-project#running-the-admin-ui) - using `LINK_PLASMA=true pnpm start` and going through the prompts.

> Note: You can link the `plasma` to a specific package of your choice with `pnpm link:plasma`.

### Local changes from platform-client

1. Make sure your Admin-UI local server is not running.
2. Install all your dependencies with `pnpm install`. If you do this after the link, the link will be broken and you will have to redo it.
3. Link the _platform-client_ package to the _admin-ui_ with `pnpm link:platform-client`. We assume you have a folder named `platform-client` as a sibling of `admin-ui` folder.
4. Build the _platform-client_ package according to its [documentation](https://github.com/coveo/platform-client#build).
5. [Launch the Admin-UI local setup](/getting-started/02-install-and-launch-project#running-the-admin-ui) - using `LINK_PLATFORM_CLIENT=true pnpm start` and going through the prompts.

> Note: You can link the `platform-client` to a specific package of your choice with `pnpm link:platform-client`.

## Quick tips for the manual bumps of pnpm packages

-   You can make a pull request with multiple dependecies update at once.
-   If possible, you should have the same version of a dependency in all packages.
-   Make sure the bump is not breaking a functionality, a green build is not a clear indication that nothing is broken.
-   Bump only minor version of a dependency. Because of the possible breaking changes, the bump of a major version should be linked to a Jira/pr and be monitored more closely.
-   Don't forget to bump dependencies in [Plasma](https://github.com/coveo/plasma) from time to time 😋

## 🚨 Here's a list of dependencies that you should **NOT** bump: 🚨

In Admin-UI project:

-   backbone
-   backbone.marionette
-   jQuery
