import {createSafeContext} from '@components/mantine';
import {PlatformNoHandlers, useQuery} from '@core/api';
import {AccessTokenUtils, doAuthorizeUser} from '@core/authentication';
import Registry from '@core/registry';
import {ReactNode, useEffect, useState} from 'react';

export interface AuthenticationContextType {
    canAccess?: boolean;
}

const [Provider, useAuthentication] = createSafeContext<AuthenticationContextType>(
    'AuthenticationProvider component not found in tree',
);

const AuthenticationProvider = ({children}: {children: ReactNode}) => {
    const isAuthenticated = useRetrieveTokenOrRedirectToLogin();

    const {data: tokenValidity} = useQuery({
        queryKey: ['validate-token'],
        queryFn: async () => {
            try {
                await PlatformNoHandlers.initialize();
                return 'valid';
            } catch (e) {
                return 'invalid';
            }
        },
        enabled: isAuthenticated,
        retry: false,
    });

    const {data: canAccess} = useQuery({
        queryKey: ['global-privileges'],
        queryFn: async () => {
            try {
                const response = await PlatformNoHandlers.privileges.getGlobalGroupPrivileges();
                return response;
            } catch (e) {
                return [];
            }
        },
        select: (globalPrivileges) => globalPrivileges?.length > 0,
        enabled: isAuthenticated && tokenValidity === 'valid',
        retry: false,
    });

    useEffect(() => {
        if (tokenValidity === 'invalid') {
            // token might be broken for a few reasons, e.g., its expired
            doAuthorizeUser();
        }
    }, [tokenValidity]);

    const isLoading = isAuthenticated && canAccess === undefined;
    return <Provider value={{canAccess: isLoading ? undefined : canAccess}}>{children}</Provider>;
};

const useRetrieveTokenOrRedirectToLogin = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    // const state = useRouterState();
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.hash.replace(/^#/, '?'));
        // Detect login and retrieve access token.
        const accessTokenFromQueryParams = queryParams.get('access_token');
        const accessTokenFromStorage = AccessTokenUtils.getToken();
        const accessTokenExists = !!accessTokenFromQueryParams || !!accessTokenFromStorage;

        // if not logged in, redirect to login page
        if (!accessTokenExists) {
            doAuthorizeUser();
        } else {
            // if logged in, retrieve access token from query params or local storage
            const expiresIn = parseInt(queryParams.get('expires_in') ?? '0', 10);

            // Access token could be there twice in the URL, make sure we use only the first.
            const decodedAccessToken = Array.isArray(accessTokenFromQueryParams)
                ? accessTokenFromQueryParams[0]
                : accessTokenFromQueryParams;

            if (!!decodedAccessToken) {
                AccessTokenUtils.setToken(decodedAccessToken, expiresIn);
            }

            Registry.register('access_token', decodedAccessToken || accessTokenFromStorage);
            Registry.register('organization_id', '');

            setIsAuthenticated(true);
            // remove authentication info from the hash
            window.location.hash = window.location.hash.split('?')[0];
        }
    }, []);
    return isAuthenticated;
};

export {AuthenticationProvider, useAuthentication};
