---
title: '@core/api'
metaTitle: 'Admin-UI | Core - API package'
metaDescription: 'A deeper look into this core package.'
---

Aims to provide all the necessary tools to perform any request on Coveo's APIs. This package is built on top of the [`@coveo/platform-client`](https://www.npmjs.com/package/@coveo/platform-client) package. In fact, it holds all the necessary instances of the platform-client that are used across the Admin UI application.

## Getting Started

**Installation**

```bash
cd ./your-package

pnpm add "@core/api@*"

or

pnpm add "@core/api@*" --recursive --filter <your-package>
```

## User Guide

### Error handling

-   The `Platform` object automatically handles server errors in a standard way (shows an error toast with the appropriate message given the error code received). This means you don't need to think about error handling when using this platform-client instance.
-   The `PlatformNoHandlers` object don't handle server errors at all, so use it with caution. This is useful when you specifically don't want the standard error handling behavior to happen because a special use-case requires it.

### PlatformClientFeatures

Set of additional features to be used in conjunction with the [`withFeatures`](https://github.com/coveo/platform-client#ad-hoc-requests) method of the platform-client.

#### useRegion

Allows to perform the next request targeting a specific region.

**Example**

```ts
import {Region} from '@coveo/platform-client';

const europeOrganizations = await Platform.withFeatures(
    PlatformClientFeatures.useRegion(Region.EU),
).organization.list();
```
