// The order of the imports here is important
// To prevent automatic reordering, voluntary empty lines are added between imports

import './reset.css';

import {DocsAppTheme} from '@configurations/docs';

import './global.css';

import {QueryClient, QueryClientProvider} from '@core/api';
import {OwnershipProvider} from '@core/debug';
import Registry from '@core/registry';
import {MDXProvider} from '@mdx-js/react';
import {createRouter, RouterProvider} from '@tanstack/react-router';
import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import {AuthenticationProvider, useAuthentication} from './auth';
import mdxComponents from './components/mdx-components';

// Import the generated route tree
import {routeTree} from './routeTree.gen';

// Import mantine code highlight styles
import '@mantine/code-highlight/styles.css';

const versionRegexp = /^\/v\d+\.\d+\.\d+-[a-f0-9]{12}\/$/; // e.g., /v11.2136.0-5c889d3470b4/
let basepath = import.meta.env.BASE_URL;
if (basepath.indexOf('/_/') !== -1) {
    // it's a demo branch. The cloudfront lambda rewrites everything after the /_/
    const parts = basepath.split('/_/');
    basepath = `${parts[0]}/_/`;
} else if (versionRegexp.test(basepath)) {
    // it's the main branch, The cloudfront lambda rewrites everything after the first /
    basepath = '/';
}
// Create a new router instance
const router = createRouter({
    routeTree,
    basepath,
    routeMasks: [],
    context: {
        auth: undefined!, // This will be set after we wrap the app in an AuthProvider
    },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router;
    }
}

// remove index.html from the URL, tanstack/react-router doesn't handle it correctly with our dynamic routes
if (window.location.href.includes('/index.html')) {
    window.location.href = window.location.href.replace('/index.html', '/');
}

const App = () => {
    const auth = useAuthentication();
    return <RouterProvider router={router} context={{auth}} />;
};

const ErrorHandler = () => {
    // do nothing, the errors are caught by the AuthenticationProvider
};
Registry.register('error_handler', ErrorHandler);

const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}});

// Render the app
const rootElement = document.getElementById('app')!;
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <StrictMode>
            <OwnershipProvider defaultOwner="frontend-foundation" defaultFramework="Mantine">
                <DocsAppTheme>
                    <MDXProvider components={mdxComponents}>
                        <QueryClientProvider client={queryClient}>
                            <AuthenticationProvider>
                                <App />
                            </AuthenticationProvider>
                        </QueryClientProvider>
                    </MDXProvider>
                </DocsAppTheme>
            </OwnershipProvider>
        </StrictMode>,
    );
}
