---
title: 'Contributing'
metaTitle: 'Admin-UI Docs | Getting started > Contributing'
metaDescription: 'Contributing guidelines for Admin-UI project'
---

Hey, frontend developer friend! 👋

You've come to the right place to contribute to the Admin-UI project.

## How to contribute properly to the Admin-UI project?

### Before creating your pull request

**Guidelines**

-   Make sure your changes in TypesScript files are fully tested.
-   We tend to avoid unnecessary comments in our code base, we strongly prefer good naming and code structure.
-   Large number of complex changes can slow down the time to merge a Pull Request. It can be helpful to make smaller PRs in these situations.
-   A Pull Request can be a broken into multiple commits which allows for better git history and easy reverts.
-   Squash your commits

**Feature flags**

When developing a new feature, it is recommended to use a feature flag to hide the feature until it is ready to be released.

Like we mention in our Best practices, you should always start by asking yourself if a [LaunchDarkly flag](/guides/launch-darkly-flag) should be used for your feature.

**Covered by automated tests**

Features should be covered by automated tests before they are made available to clients on production.
There are 3 types of tests you can use to test your code:

-   **Unit tests**: test individual functions or classes

    -   We use [Jest](https://jestjs.io)

-   **Component tests**: test interactions between different parts of your application.

    -   We use [Jest](https://jestjs.io), [React Testing Library](https://testing-library.com/docs/react-testing-library/intro) & [User Event (Testing Library)](https://testing-library.com/docs/user-event/intro)

-   **End-to-end tests**: test the application as a whole, simulating a user's interaction with it.
    -   We use [Playwright](https://playwright.dev)
    -   This can be done using the [Playwright end-to-end framework](https://github.com/coveo-platform/admin-ui/blob/master/e2e/README.md) included in Admin-UI project
    -   More information on Playwright can be found in the [Playwright Guidelines](/tests/playwright-guidelines)
    -   Relevant end-to-end tests can be run in your pull request by editing the End-to-end section of the template.
        Add the tags you wish to execute according to the [tags listed in this file](https://github.com/coveo-platform/admin-ui/blob/master/e2e/config/tags.ts).
        ![e2e tags in the description of your pull request](./images/contributing-e2e-tags-description-pull-request.png)
        Once selected, a new optional check will start.
        This will launch the end-to-end test on chrome using your pull request code, in the demo branch.
        You can see its result and Details here.
        ![e2e tags](./images/contributing-e2e-check.png)

### When creating your pull request

Following the Pull Request journey will make your code changes easier to review and therefore easier to merge.
Spending a bit more time in preparation can save everyone time. Be courteous as your team is taking time out of their day to review your changes.

1. If you have any doubt about what you're doing, you can poke the [Frontend Foundation](https://coveord.atlassian.net/wiki/spaces/PENG/pages/4197777463/Frontend+foundation+-+Team) team members in the [#admin-ui-guild](https://coveo.slack.com/archives/C01M10DME91) Slack channel.
   We'll be able to guide you and implicate the UX and DOC team if needed.

2. The UX of a feature should be approved before you start coding ([#support-design](https://coveo.slack.com/archives/C06KN1B85C1) for questions)

3. Create yourself a branch and make the desired changes: branch name should include the JIRA you're working on eg. `fix/ADUI-1234-fix-something` or `feature/ADUI-1234-fix-something`

4. Commit your changes along with appropriate commit messages.

    - We recommend following the [Conventional Commits](https://www.conventionalcommits.org/en/v1.0.0/) to get better quality commit messages.
    - Don't hesitate to add your JIRA ticket number to your commit, it's good for keeping a clean history.
      Here are some simple examples of commit messages:
        - `fix: removes the big bug - ADUI-1234`
        - `feat: my new feature (ADUI-1234)`
        - `feat: my new feature [ADUI-1234]`

5. Review your own code before you press the "Create pull request" button. You can see the diff inside GitHub and verify all the changes that you have made and remove any code that shouldn't be in there.

    - Don't submit until ready. You can push a branch if you want others to check your code without submitting a Pull Request or you can create a [draft Pull Request](https://github.blog/2019-02-14-introducing-draft-pull-requests/).
    - Sometimes a video explaining/demoing what you're changing can benefit the reviewer. Some recommendations for software include:
        - **Linux**: use [Peek](https://github.com/phw/peek) for creating a GIF - use [Kazam](https://www.linuxbabe.com/multimedia/install-kazam-screencaster) for creating a video
        - **MacOS**: use [native functionality](https://support.apple.com/en-us/HT208721) for creating a video
        - **Windows**: use [ScreenToGif](https://www.screentogif.com) for creating a GIF or a video

6. Submit your pull request

7. If you would ask questions yourself about some patterns or lines of code, answer those questions right away for other people.

8. If the CI has finished building successfully, it will add a link for the demo in comment.

9. Respond to each reviewer. It's good etiquette to reply if you're resolving it.

    - Comments are still preferred as the method of communication to keep visibility, and it helps others with learning.
    - It can be quicker to communicate through Slack (keeps the Pull Request clean).
    - If work is still in progress, you could convert the Pull Request into a draft at the top right under Reviewers on GitHub by selecting the `Still in Progress? Convert to draft`.

10. Click the checkbox under the Compliance section to trigger the compliance check. [More information at the Compliance Check](/getting-started/06-continuous-delivery#compliance-check) documentation.

11. Once your Pull Request gets enough approvals (at least 1 member of our team, one from UX (if it's a visual change) and if you created/changed some string someone from DOC), you can merge (or ask us to do so if you don't have the required credentials). We also suggest that you add (if not done already) your team members as reviewers to test the functionalities and style of your implementation.

12. Our automated build system will take care of the rest.
    - If the Pull Request build fails, it can help to check why. People tend to avoid reviews of failing PRs.

> Note: I would like to know where my task is and in which it is released? [This information can be found in your task on the Jira app](/getting-started/06-continuous-delivery#how-do-i-know-which-version-of-coveo-release-is-deployed-in-any-environment-).

### What are good practices for the Continuous delivery process?

Here are a few guidelines to deliver content continuously and safely to production:

-   Make sure the 'QA Testable' and 'Requires Public Documentation' fields are filled on your JIRA.
    The sooner we know something is testable or requires documentation (or not), the better!
-   The 'demo_tested' label can be used within a pull request to confirm the feature/fix was properly tested.
    It will set the 'QA Testable' value on JIRA to 'Was tested' automatically.
-   Features behind a [LaunchDarkly flag](/guides/launch-darkly-flag) should be validated both with and without the flag enabled.
-   Feel free to try the [Playwright](https://github.com/coveo-platform/admin-ui/blob/master/e2e/README.md) end-to-end testing tool.
    More coverage for new features leads to better overall quality!

> Note: [More information at the Continuous Delivery](/getting-started/06-continuous-delivery#compliance-check) documentation.

## How to review a pull request correctly?

If you've reached this point, it's because your team needs you to validate the code of one of your colleagues.

This time is beneficial for several reasons:

-   It can help to gain better knowledge of the project
-   It makes it easier to learn good practices and things to avoid
-   It should be a time for exchanging ideas with your colleagues
-   It will also give you a better understanding of the products we've developed, as you'll be testing them

Code reviews should be done in a timely manner.
Part of your daily routine should include code reviews.
Always be kind in your interactions. We're all here to help each other ❤️.

### Where can I find pull requests to review?

Github has several features that allow you to filter pull requests for review:

-   [Notifications](https://github.com/notifications?query=reason%3Aparticipating) lets you see pull requests where you're participating, so you can easily see the activity of your pull requests and others as soon as an action is taken on them.
    [More information on Notifications in github](https://docs.github.com/fr/account-and-profile/managing-subscriptions-and-notifications-on-github)
    ![Github's notifications](./images/contributing-review-notifications.png)
-   [Pull Requests](https://github.com/pulls/review-requested)
    ![Github's pull requests](./images/contributing-review-pull-request.png)

### The comments

-   Question things you do not understand and challenge people.
-   Keep the comments on the Pull Request within the scope of the Pull Request.
-   Avoid using GitHub "Request Change" as it slows down the merge flow. Comments need to be resolved before merge and can be equally useful.

### The code

-   For small changes, giving a code suggestion will generally resolve the suggestion faster than questioning the Pull Request owner choices.
-   We don't always need to hold up PRs for perfection (nitpicking). Sometimes it's good for discussion or understanding why something is done a certain way.
-   Every functionality should be well tested is covered by a battery of tests like:
    -   unit tests
    -   component tests
    -   end-to-end tests
-   The texts supplied by the documentation team must be in translation files.
    -   the documentation team is the maintainer of the translation files and will be notified of any changes to these files
    -   when a file isn't in a translation file, it's more complicated to find, and may not be translated when we want to switch to a new language.

### The functionality in its demo environment

When creating the pull request, [our CI will launch processes to create the demo environments](/getting-started/06-continuous-delivery#demo-app), and a comment will be added to the pull request with the correct links.

-   When testing code under a feature flag, make sure to look into the feature behavior both **with** and **without** the flag enabled.
-   When testing the demo, the unofficial dark mode and plugins may render things differently.
-   When testing the demo, we want to make sure that the code works properly and that the features are implemented correctly.
    -   it's important to look at and understand the associated Jira and project figma to understand the features implemented
    -   you can also ask the PR author to explain the features implemented
-   When we test the demo, we should also try to break the functionality to see how it reacts.
    -   try to find edge cases
    -   click on different buttons and components to see how they react
    -   once you've tested it, don't forget to add the label `demo_tested` to indicate that you've tested the demo.
        This will update the **QA Testable** field in the JIRA associated with the PR to **Yes**.
        Even if it's already there, you can take it out and put it back in, to show that the feature has been tested by several people.
-   As a front-end developer, you can also go one step further:
    -   test the demo on different browsers (Chrome, Firefox, Safari, Edge)
    -   test the demo with accessibility tools
    -   test the demo and see what happens in the browser console
    -   test the demo on smaller screens. The minimum size we need to support with the menu open is **1500px** wide and without horizontal scrollbar
    -   test the demo with minimal or missing privileges

### Admin UI Reviewers team

If you wish, you can also join the [Admin UI Reviewers](https://github.com/orgs/coveo-platform/teams/admin-ui-reviewers) team, which brings together several people from different R&D teams to review portions of the code that do not belong to any one team.
You can request it from the [Frontend Foundation](https://coveord.atlassian.net/wiki/spaces/PENG/pages/4197777463/Frontend+foundation+-+Team) team.
