---
title: 'Install and launch'
metaTitle: 'Admin-UI Docs | Getting started > Install and launch'
metaDescription: 'How to install and launch the Admin-UI project locally'
---

All the commands in the instructions must be run at the root of the `admin-ui` folder.

## Installing the project

### 1 - Clone the project

```bash
git clone git@github.com:coveo/admin-ui.git

# Move into the project, it will be useful for the next step
cd ./admin-ui
```

### 2 - Install the project dependencies

```bash
pnpm install
```

Once the operation is complete, you should see a `node_modules` folder at the root of the `admin-ui` folder and in each package.

### 3 - Installing an SSL certificate (optional)

Setting up an SSL certificate and using a domain is handy for testing part of the OAuth2 flow in the Sources section of the Platform application.
It may also be useful in other sections of the Admin-UI project.

To create a development SSL certificate, we'll use the [mkcert](https://github.com/FiloSottile/mkcert) project.
This cross-platform project is easy to use. [Please follow the installation instructions for your operating system.](https://github.com/FiloSottile/mkcert?tab=readme-ov-file#installation)

Open a terminal (use PowerShell or cmd with Windows)

```bash
# create your `.cert` folder at the root of the project
mkdir .cert

# create your certificate and key in the same file
mkcert -key-file .cert/_cert.pem -cert-file .cert/_cert.pem 'local.cloud.coveo.com'
```

Enable the use of SSL in our applications via an environment variable.

```bash
# for @apps/platform
echo 'USE_SSL=true' > ./apps/platform/.env.local

# for @apps/commerce-hub
echo 'USE_SSL=true' > ./apps/commerce-hub/.env.local

# for @apps/knowledge-hub
echo 'USE_SSL=true' > ./apps/knowledge-hub/.env.local
```

> Note: The `.env.local` files will not be versioned, useful for activating things only on your development machine.

Applications will be automatically launched on these URLs if you choose to activate SSL on your local machine.

-   [https://local.cloud.coveo.com:9000](https://local.cloud.coveo.com:9000) for **Platform** application
-   [https://local.cloud.coveo.com:9001](https://local.cloud.coveo.com:9001) for **CommerceHub** application
-   [https://local.cloud.coveo.com:9002](https://local.cloud.coveo.com:9002) for **KnowledgeHub** application

## Running the Admin-UI

Now it's time to launch the Admin-UI project, you need to run this command below:

```bash
pnpm start
```

This command will by default build all the packages with `pnpm build` and `pnpm type-check`, which is required for Intellisense (code completion) to work properly.

You will then be prompted to select which packages you wish to hot reload, you can navigate with arrow keys, you can filter the list of packages by typing part of the package name, and select a package by pressing `tab`. By default, no packages are selected.

![Launches command prompt to help you get started](./images/install-and-launch-project-command-prompt.png)

To make starting your selected package set easier, you can also configure a `start.config.json` file in the project root directory. You can read more about that in the [Command Line Interface](/getting-started/03-command-line-interface#start-command-configuration-file) section.

Once your server is started, a new window of your machine's default browser should automatically open and load your Administration Console local version.

Applications are automatically launched on urls:

-   [http://localhost:9000](http://localhost:9000) for **Platform** application
-   [http://localhost:9001](http://localhost:9001) for **CommerceHub** application
-   [http://localhost:9002](http://localhost:9002) for **KnowledgeHub** application
-   [http://localhost:3000](http://localhost:3000) for **Docs** application

### Choose the environment you want

By default, your local **Admin-UI project** build uses the backend's dev endpoint to launch your applications.
If you want to use another endpoint, specify it in a URL parameter:

-   development environment: [http://localhost:9000/?dev](http://localhost:9000/?dev)
-   staging environment: [http://localhost:9000/?stg](http://localhost:9000/?stg)
-   local environment: `http://localhost:9000/?platform=<your local backend address here>`

> Note: This also applies to other business applications.

### Running with the production endpoint

While the local admin-ui setup is able to run using the production endpoint, it requires some additional steps to make it work.
This is caused by the fact that the Authorization Server in production is not allowed to redirect to a local URL.
To workaround this behavior, we simply need our user to already be authenticated in production, it will avoid the need to be redirected by the Authorization Server.

1. Open an org in [production](https://platform.cloud.coveo.com/) and copy the `coveo-access-token` from the localStorage to your clipboard.
2. Have your admin-ui local setup running in a different browser tab and open the DevTools
3. Pause the javascript execution of your local setup from the `sources` tab in the DevTools or by pressing `F8`.
4. Paste the prod `coveo-access-token` from your clipboard in the `coveo-access-token` field in the localStorage of your local setup.
5. Change the url from `http://localhost:9000/?dev#ORGID` to `http://localhost:9000/?production#ORGID` and press **ENTER**
6. You can use the org picker to switch to a different region if needed

Since the Australia region only has a [production environment](https://platform-au.cloud.coveo.com/), the above procedure will allow you to test your local changes in this region.

_You can execute the same steps to run the local setup in HIPAA (localhost:9000/?hipaa#ORGID) also._
